
/**
 * @file
 * Global utilities.
 *
 */
(function ($, Drupal) {

  'use strict';

  // Drupal.behaviors.berl = {
  //   attach: function (context, settings) {
  //
  //
  //   }
  // };


  // $(document).ready(function () {
  //
  // });



})(jQuery, Drupal);
